import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { H1 } from "../components/page-elements/h1"
import styled from "styled-components"
import { device } from "../utils/responsive"
const Title = styled(H1)`
  text-align: center;
  font-weight: 600;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0rem 17rem;
  text-align: justify;
  width: 100vh;
  text-align: justify;
  @media ${device.tablet} {
    padding: 4rem;
  }
  @media ${device.laptop} {
    padding: 0rem;
  }
  & h1,
  h2 {
    font-weight: 600;
  }
  & h3 {
    font-weight: 600;
    margin: 1rem 0;
  }
  & a {
    color: ${({ theme }) => theme.colours.black};
    cursor: pointer;
    :hover {
      color: ${({ theme }) => theme.colours.logoLeaf};
    }
  }
`
const Privacy = () => {
  const {
    mdx: {
      frontmatter: { title },
      body,
    },
  } = useStaticQuery(graphql`
    query Terms {
      mdx(fileAbsolutePath: { regex: "/terms/" }) {
        frontmatter {
          title
        }
        body
      }
    }
  `)
  return (
    <Container>
      <Title>{title}</Title>
      <MDXRenderer>{body}</MDXRenderer>
    </Container>
  )
}

export default Privacy
